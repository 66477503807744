// app/javascript/controllers/utils/forms/admission_form_controller.js
import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        this.initializePickers()
        this.toggleAmountFields()
    }

    initializePickers() {
        let exitTimePickerElement = document.querySelector("#exit-time-picker");
        let entryTimePickerElement = document.querySelector("#entry-time-picker");
        exitTimePickerElement.onkeypress = () => false
        entryTimePickerElement.onkeypress = () => false

        let exitTimePicker = flatpickr("#exit-time-picker", {
            enableTime: true,
            noCalendar: false,
            time_24hr: true,
            allowInput: true,
            dateFormat: "d-m-Y H:i",
        });

        exitTimePickerElement.disabled = exitTimePicker.selectedDates.length <= 0;

        let entryTimePicker = flatpickr("#entry-time-picker", {
            enableTime: true,
            minDate: "today",
            noCalendar: false,
            time_24hr: true,
            allowInput: true,
            dateFormat: "d-m-Y H:i",
            onChange: (selectedDates, dateStr, instance) => {
                if (selectedDates.length > 0) {
                    const entryTime = selectedDates[0];
                    let exitTime;

                    if (exitTimePicker.selectedDates[0] && exitTimePicker.selectedDates[0].getDate() !== entryTime.getDate()) {
                        exitTime = exitTimePicker.selectedDates[0];
                    } else {
                        exitTime = new Date(entryTime);
                        exitTime.setHours(entryTime.getHours() + 2);
                        exitTime.setMinutes(entryTime.getMinutes());
                    }
                    exitTimePicker.setDate(exitTime);
                    exitTimePicker.set('minDate', new Date(entryTime.getFullYear(), entryTime.getMonth(), entryTime.getDate()));

                    if (exitTimePicker.selectedDates[0] && exitTimePicker.selectedDates[0].toDateString() === entryTime.toDateString()) {
                        exitTimePicker.set('minTime', entryTime.getHours() + ":" + entryTime.getMinutes());
                        exitTimePicker.set('maxTime', exitTime.getHours() + ":" + exitTime.getMinutes());
                    } else {
                        exitTimePicker.set('minTime', '00:00');
                        exitTimePicker.set('maxTime', '23:59');
                    }
                    exitTimePickerElement.disabled = false;
                }
            }
        });

        if (entryTimePicker.selectedDates.length > 0) {
            entryTimePicker.config.onChange[0](entryTimePicker.selectedDates, entryTimePicker.input.value, entryTimePicker);
        }

        exitTimePicker.config.onChange.push((selectedDates, dateStr, instance) => {
            if (selectedDates.length > 0) {
                const exitDate = selectedDates[0];
                const entryDate = entryTimePicker.selectedDates[0];

                if (exitDate.toDateString() === entryDate.toDateString()) {
                    exitTimePicker.set('minTime', entryDate.getHours() + ":" + entryDate.getMinutes());
                    exitTimePicker.set('maxTime', new Date(entryDate.getTime() + 2 * 60 * 60 * 1000).getHours() + ":" + entryDate.getMinutes());
                } else {
                    exitTimePicker.set('minTime', '00:00');
                    exitTimePicker.set('maxTime', '23:59');
                }
            }
        });
    }

    toggleAmountFields() {
        const deliveryType = document.querySelector('#delivery_type');
        const pickupDiv = document.querySelector('#amount_for_pickup');
        const deliveryDiv = document.querySelector('#amount_for_delivery');

        const pickupField = document.querySelector('#amount_for_pickup input');
        const deliveryField = document.querySelector('#amount_for_delivery input');

        deliveryType.addEventListener('change', (event) => {
            switch(event.target.value) {
                case 'Recogida':
                    pickupDiv.style.display = 'block';
                    deliveryField.removeAttribute("required");
                    deliveryDiv.style.display = 'none';
                    break;
                case 'Entrega':
                    pickupField.removeAttribute("required");
                    pickupDiv.style.display = 'none';
                    deliveryDiv.style.display = 'block';
                    break;
                case 'Recogida y entrega':
                    pickupField.setAttribute("required", "required");
                    deliveryField.setAttribute("required", "required");
                    pickupDiv.style.display = 'block';
                    deliveryDiv.style.display = 'block';
                    break;
            }
        });
    }
}