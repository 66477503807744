"use strict";
// DOCS: https://javascript.info/cookie

// Class definition
var Cookie = function () {
    return {
        // returns the cookie with the given name,
        // or undefined if not found
        get: function (name) {
            var matches = document.cookie.match(new RegExp(
                "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
            ));

            return matches ? decodeURIComponent(matches[1]) : null;
        },

        // Please note that a cookie value is encoded,
        // so getCookie uses a built-in decodeURIComponent function to decode it.
        set: function (name, value, options) {
            if (typeof options === "undefined" || options === null) {
                options = {};
            }

            options = Object.assign({}, {
                path: '/'
            }, options);

            if (options.expires instanceof Date) {
                options.expires = options.expires.toUTCString();
            }

            var updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

            for (var optionKey in options) {
                if (options.hasOwnProperty(optionKey) === false) {
                    continue;
                }

                updatedCookie += "; " + optionKey;
                var optionValue = options[optionKey];

                if (optionValue !== true) {
                    updatedCookie += "=" + optionValue;
                }
            }

            document.cookie = updatedCookie;
        },

        // To remove a cookie, we can call it with a negative expiration date:
        remove: function (name) {
            this.set(name, "", {
                'max-age': -1
            });
        }
    }
}();

export {Cookie}
