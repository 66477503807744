import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="components--base--turbo-modal-upload-document
export default class extends Controller {

    connect() {
        this.startPolling();
        this.initializeModal('#vat_upload', '#vat_document');
        this.initializeModal('#cap_upload', '#cap_document');
        this.initializeModal('#itv_upload', '#itv_document');
        this.initializeModal('#circulation_permit_upload', '#circulation_permit_document');
        this.initializeModal('#driving_license_upload', '#driving_license_document');
        this.initializeModal('#vehicle_insurance_upload', '#vehicle_insurance_document');
    }

    initializeModal(modalId, buttonId) {
        const modalElement= document.querySelector(modalId);
        const modal = new bootstrap.Modal(modalElement);

        const button = document.querySelector(buttonId);
        if (button) {
            button.addEventListener('click', (event) => {
                event.preventDefault();
                modal.show();
            });
        }

        const closeButtons = document.querySelectorAll('.modal-close');
        closeButtons.forEach((closeButton) => {
            closeButton.addEventListener('click', (event) => {
                event.preventDefault();
                modal.hide();
            });
        });
    }

    startPolling() {
        if (document.querySelector('td.processing-status')) {
            this.pollingInterval = setInterval(() => {
                fetch(`/job_status`)
                    .then(response => {
                        if (!response.ok || response.status === 204) {
                            location.reload();
                        }
                        return response.text();
                    })
                    .then(text => {
                        let data = text ? JSON.parse(text) : {};
                        if (data.job_status === 'completed' || data.concordance_job_status === 'completed') {
                            location.reload();
                        }
                    })
                    .catch(error => {
                        location.reload();
                    });
            }, 4000);
        }
    }
}
