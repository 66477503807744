import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        this.aiClassified = this.data.get('aiClassified');
        this.unclassified = this.data.get('unclassified');
        this.itvWithDate = this.data.get('itvWithDate');
        this.itvWithoutDate = this.data.get('itvWithoutDate');
        this.vatWithDate = this.data.get('vatWithDate');
        this.vatWithoutDate = this.data.get('vatWithoutDate');

        this.categoryConfirmedText = this.data.get('categoryConfirmedText');
        this.categoryUnconfirmedText = this.data.get('categoryUnconfirmedText');
        this.validDateText = this.data.get('validDateText');
        this.invalidDateText = this.data.get('invalidDateText');

        this.initializeDocsChart()
        this.initializeItvChart()
        this.initializeVatChart()
    }

    initializeDocsChart() {
        let aiClassified = parseInt(this.aiClassified);
        let unclassified = parseInt(this.unclassified);
        let options = {
            series: [aiClassified, unclassified],
            chart: {
                width: 450,
                type: 'pie',
            },
            labels: [this.categoryConfirmedText, this.categoryUnconfirmedText],
            colors: ['#606da1', '#E9AF4B'],
            responsive: [{
                breakpoint: 300,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            },
                {
                    breakpoint: 1420,
                    options: {
                        chart: {
                            width: 300
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
            dataLabels: {
                formatter: function(val, opts) {
                    return opts.w.globals.series[opts.seriesIndex]
                },
                style: {
                    fontSize: '14px'
                }
            },
            legend: {
                fontSize: '14px',
                labels: {
                    useSeriesColors: true
                },
                formatter: function(val, opts) {
                    return val + ": " + opts.w.globals.series[opts.seriesIndex]
                }
            }
        };

        let chart = new ApexCharts(document.querySelector("#chart"), options);
        chart.render();
    }

    initializeItvChart() {
        let itvWithDate = parseInt(this.itvWithDate);
        let itvWithoutDate = parseInt(this.itvWithoutDate);
        let options = {
            series: [itvWithDate, itvWithoutDate],
            chart: {
                width: 450,
                type: 'pie',
            },
            labels: [this.validDateText, this.invalidDateText],
            colors: ['#606da1', '#E9AF4B'],
            responsive: [{
                breakpoint: 300,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            },
                {
                    breakpoint: 1420,
                    options: {
                        chart: {
                            width: 300
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
            dataLabels: {
                formatter: function(val, opts) {
                    return opts.w.globals.series[opts.seriesIndex]
                },
                style: {
                    fontSize: '14px'
                }
            },
            legend: {
                fontSize: '14px',
                labels: {
                    useSeriesColors: true
                },
                formatter: function(val, opts) {
                    return val + ": " + opts.w.globals.series[opts.seriesIndex]
                }
            }
        };

        let chart = new ApexCharts(document.querySelector("#itv_chart"), options);
        chart.render();
    }

    initializeVatChart() {
        let vatWithDate = parseInt(this.vatWithDate);
        let vatWithoutDate = parseInt(this.vatWithoutDate);
        let options = {
            series: [vatWithDate, vatWithoutDate],
            chart: {
                width: 450,
                type: 'pie',
            },
            labels: [this.validDateText, this.invalidDateText],
            colors: ['#606da1', '#E9AF4B'],
            responsive: [{
                breakpoint: 300,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            },
                {
                    breakpoint: 1420,
                    options: {
                        chart: {
                            width: 300
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
            dataLabels: {
                formatter: function(val, opts) {
                    return opts.w.globals.series[opts.seriesIndex]
                },
                style: {
                    fontSize: '14px'
                }
            },
            legend: {
                fontSize: '14px',
                labels: {
                    useSeriesColors: true
                },
                formatter: function(val, opts) {
                    return val + ": " + opts.w.globals.series[opts.seriesIndex]
                }
            }
        };

        let chart = new ApexCharts(document.querySelector("#vat_chart"), options);
        chart.render();
    }
}