import ApplicationController from "../application_controller"

// Connects to data-controller="devise--registrations--new"
export default class extends ApplicationController {

    initialize() {
        this.form = document.querySelector('#kt_sign_up_form')
        this.submitButton = document.querySelector('#kt_sign_up_submit');
        this.passwordMeter = KTPasswordMeter.getInstance(this.form.querySelector('[data-controller="components--password-meter"]'));
    }

    connect() {
        let context = this
        let form = this.form
        let submitButton = this.submitButton
        let passwordMeter = this.passwordMeter
        let tosCheckbox = document.querySelector('#tos_agreement_check');
        tosCheckbox.addEventListener('change', function() {
            tosCheckbox.value = this.checked ? 'true' : '';
        });

        // Init form validation rules. For more info check the FormValidation plugin's official documentation:https://formvalidation.io/
        let validator = FormValidation.formValidation(
            form,
            {
                locale: 'es_ES',
                localization: FormValidation.locales.es_ES,
                fields: {
                    'user[name]': {
                        validators: {
                            notEmpty: {
                                message: 'Nombre no puede estar en blanco'
                            },
                        }
                    },
                    'user[surname]': {
                        validators: {
                            notEmpty: {
                                message: 'Los apellidos no pueden estar en blanco'
                            },
                        }
                    },
                    'user[email]': {
                        validators: {
                            notEmpty: {
                                message: 'Email no puede estar en blanco'
                            },
                            emailAddress: {}
                        }
                    },
                    'user[document_number]': {
                        validators: {
                            notEmpty: {
                                message: 'El DNI no puede estar en blanco'
                            },
                        }
                    },
                    'user[phone]': {
                        validators: {
                            notEmpty: {
                                message: 'El teléfono no puede estar en blanco'
                            },
                        }
                    },
                    'user[password]': {
                        validators: {
                            notEmpty: {
                                message: 'La contraseña no puede estar en blanco'
                            },
                            callback: {
                                message: 'Por favor introduzca una contraseña válida',
                                callback: function (input) {
                                    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d.\S]{6,}$/;
                                    return passwordRegex.test(input.value);
                                }
                            }
                        }
                    },
                    'user[password_confirmation]': {
                        validators: {
                            notEmpty: {
                                message: 'Confirmación de contraseña no puede estar en blanco'
                            },
                            identical: {
                                compare: function () {
                                    return form.querySelector('[name="user[password]"]').value;
                                },
                            }
                        }
                    },
                    'user[tos_agreement]': {
                        validators: {
                            notEmpty: {
                                message: 'Debes aceptar los términos y condiciones'
                            },
                        }

                    }
                },
                plugins: {
                    trigger: new FormValidation.plugins.Trigger({
                        event: {
                            password: false
                        }
                    }),
                    bootstrap: new FormValidation.plugins.Bootstrap5({
                        rowSelector: '.fv-row',
                        eleInvalidClass: '',
                        eleValidClass: ''
                    })
                }
            }
        );

        // Handle form submit
        submitButton.addEventListener('click', function (e) {
            e.preventDefault();

            validator.revalidateField('user[password]');

            validator.validate().then(function (status) {
                if (status == 'Valid') {
                    // Show loading indication
                    submitButton.setAttribute('data-kt-indicator', 'on');

                    // Disable button to avoid multiple click
                    submitButton.disabled = true;

                    form.submit();
                }
            });
        });

        // Handle password input
        form.querySelector('input[name="user[password]"]').addEventListener('input', function () {
            const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d.\S]{6,}$/;
            if (!passwordRegex.test(input.value)) {
                validator.updateFieldStatus('user[password]', 'NotValidated');
            }
        });
    }
}
